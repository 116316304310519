
.player-bullet{
	transform:scaleY(-1);
	background: url('/assets/shoot.png') 0px 0px no-repeat;
	animation: throttle 0.4s steps(3) linear infinite;
}

@keyframes throttle {
	0%   {  background-position: 0px 0px; }
	50%  {  background-position: -10px 0px; }
	100% {  background-position: 0px 0px; }
}
