body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.startButton{
	width:80px;
	height:50px;
	position:absolute;
	top:calc(50vh - 25px);
	left:calc(50vw - 50px);
}

.game-shooter{
	position: relative;
	overflow: hidden;
}
.game-shooter:after {
	content: "";
	position: absolute;
	z-index: 0;
	
	background:url('/assets/starfield-small-2.png');
	
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	-webkit-animation: movestars 100s linear infinite;
	        animation: movestars 100s linear infinite;
	
	/*
	background:url('/assets/blackhole.png') center center no-repeat;
	width: 200%;
	height: 200%;
	top: -50%;
	left: -50%;

	animation: rotateblackhole 1000s linear infinite; */
}
.game-shooter:before {
	content: "";
	position: absolute;
	z-index: -1;
	background:url('/assets/starfield-small.png');
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	-webkit-animation: movestars 400s linear infinite;
	        animation: movestars 400s linear infinite;
}
body {
	background-color:black;
}


@-webkit-keyframes rotateblackhole {
	0%   {  
		-webkit-transform:rotate(0deg);  
		        transform:rotate(0deg); 	
	}
	50%   {  
		-webkit-transform:rotate(180deg);  
		        transform:rotate(180deg); 
	}
	100%   {  
		-webkit-transform:rotate(359.9deg);  
		        transform:rotate(359.9deg); 
	}
}


@keyframes rotateblackhole {
	0%   {  
		-webkit-transform:rotate(0deg);  
		        transform:rotate(0deg); 	
	}
	50%   {  
		-webkit-transform:rotate(180deg);  
		        transform:rotate(180deg); 
	}
	100%   {  
		-webkit-transform:rotate(359.9deg);  
		        transform:rotate(359.9deg); 
	}
}


@-webkit-keyframes movestars {
	from  {  
		background-position:0 0; 	
	}
	to   {  
		background-position:0 100%;
	}
}


@keyframes movestars {
	from  {  
		background-position:0 0; 	
	}
	to   {  
		background-position:0 100%;
	}
}

.player-turn-right{
	-webkit-transform:scaleY(-1) rotate(10deg);
	        transform:scaleY(-1) rotate(10deg)
}
.player-turn-left{
	-webkit-transform:scaleY(-1) rotate(-10deg);
	        transform:scaleY(-1) rotate(-10deg)
}
.player-no-turn{
	-webkit-transform:scaleY(-1);
	        transform:scaleY(-1)
}
.player{
	transition: -webkit-transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
	transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
	background: url('/assets/player.png') 0px 0px no-repeat;
	-webkit-animation: playerThrottle 0.2s steps(2) infinite;
	        animation: playerThrottle 0.2s steps(2) infinite;
}

@-webkit-keyframes playerThrottle {
	0%   {  background-position: 0px 0px; }
	100%  {  background-position: -59px 0px; }
}

@keyframes playerThrottle {
	0%   {  background-position: 0px 0px; }
	100%  {  background-position: -59px 0px; }
}


.player-bullet{
	-webkit-transform:scaleY(-1);
	        transform:scaleY(-1);
	background: url('/assets/shoot.png') 0px 0px no-repeat;
	-webkit-animation: throttle 0.4s steps(3) linear infinite;
	        animation: throttle 0.4s steps(3) linear infinite;
}

@-webkit-keyframes throttle {
	0%   {  background-position: 0px 0px; }
	50%  {  background-position: -10px 0px; }
	100% {  background-position: 0px 0px; }
}

@keyframes throttle {
	0%   {  background-position: 0px 0px; }
	50%  {  background-position: -10px 0px; }
	100% {  background-position: 0px 0px; }
}

.explosion{
	transition: -webkit-transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
	transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
	background: url('/assets/explosion.png') 0px 0px no-repeat;
	-webkit-animation: boom 0.4s steps(5) infinite;
	        animation: boom 0.4s steps(5) infinite;
}

@-webkit-keyframes boom {
	from {  background-position: 0px 0px; }
	to {  background-position: -150px 0px; }
}

@keyframes boom {
	from {  background-position: 0px 0px; }
	to {  background-position: -150px 0px; }
}

.enemy1{
	transition: -webkit-transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
	transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
	background: url('/assets/enemy1.png') 0px 0px no-repeat;
	-webkit-animation: enemyThrottle 0.2s steps(2) infinite;
	        animation: enemyThrottle 0.2s steps(2) infinite;
}
.enemy2{
	transition: -webkit-transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
	transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
	background: url('/assets/rocket.png') 0px 0px no-repeat;
	-webkit-animation: enemyThrottle 0.2s steps(2) infinite;
	        animation: enemyThrottle 0.2s steps(2) infinite;
}

@-webkit-keyframes enemyThrottle {
	0%   {  background-position: 0px 0px; }
	100%  {  background-position: -59px 0px; }
}

@keyframes enemyThrottle {
	0%   {  background-position: 0px 0px; }
	100%  {  background-position: -59px 0px; }
}

.score{
	color:yellow;
	font-weight:bold;
	font-size:2em;
	position: absolute;
	top:0;
	right:10px;
	text-align: right;
}
.score.hidden{display:none;}
.score.center{
    width: 100vw;
    top: 55vh;
    left: 0;
    text-align: center;
}

