.game-shooter{
	position: relative;
	overflow: hidden;
}
.game-shooter:after {
	content: "";
	position: absolute;
	z-index: 0;
	
	background:url('/assets/starfield-small-2.png');
	
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	animation: movestars 100s linear infinite;
	
	/*
	background:url('/assets/blackhole.png') center center no-repeat;
	width: 200%;
	height: 200%;
	top: -50%;
	left: -50%;

	animation: rotateblackhole 1000s linear infinite; */
}
.game-shooter:before {
	content: "";
	position: absolute;
	z-index: -1;
	background:url('/assets/starfield-small.png');
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	animation: movestars 400s linear infinite;
}
body {
	background-color:black;
}


@keyframes rotateblackhole {
	0%   {  
		transform:rotate(0deg); 	
	}
	50%   {  
		transform:rotate(180deg); 
	}
	100%   {  
		transform:rotate(359.9deg); 
	}
}


@keyframes movestars {
	from  {  
		background-position:0 0; 	
	}
	to   {  
		background-position:0 100%;
	}
}