
.player-turn-right{
	transform:scaleY(-1) rotate(10deg)
}
.player-turn-left{
	transform:scaleY(-1) rotate(-10deg)
}
.player-no-turn{
	transform:scaleY(-1)
}
.player{
	transition: transform 0.2s ease-out;
	background: url('/assets/player.png') 0px 0px no-repeat;
	animation: playerThrottle 0.2s steps(2) infinite;
}

@keyframes playerThrottle {
	0%   {  background-position: 0px 0px; }
	100%  {  background-position: -59px 0px; }
}
